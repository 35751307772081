import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();



const swiper = new Swiper('.swiper', {
    // Optional parameters
    loop: true,
    slidesPerView: 'auto',
    lazyPreloadPrevNext: 2,
    spaceBetween: 0,
    centeredSlides: false,
    slidesOffsetBefore: 20,
});
  

// const carousels = document.querySelectorAll(".f-carousel");
// const carouselOptions = { 
//     infinite: true,
//     Dots: false,
//     Navigation: true,

//     dragFree: true,

//     on: {
//         load: (carousel) => {
//           carousel.updateMetrics();
//         },
//     },
// };

// carousels.forEach(carousel => {
//     new Carousel(carousel, carouselOptions);
// })